import React, {useState, useEffect} from 'react'
import {
  LegacyCard,
  LegacyStack,
  Icon,
  Text,
  Select,
  Button,
  RangeSlider,
  Tooltip,
  Checkbox,
} from "@shopify/polaris"
import { DeleteIcon, InfoIcon } from "@shopify/polaris-icons";
import MulProductPickerModal from '../../../../../../components/mulProductPickerModal'
import TagModal from '../../../checkout/tagModal';
import ProductTypeModal from '../../../checkout/productTypeModal';
// import Customization from './customization';
// import ABTesting from './abTesting'
import styled from 'styled-components'
import update from 'immutability-helper'
import gql from "graphql-tag"
import { useLazyQuery } from 'react-apollo';
// import SmartRulesCard from './smartRulesCard';
import DiscountCart from '../../discountCart/discountCart';
import DiscountCard from '../../../postpurchase/discountCard'
import CollectionModal from '../components/collectionModal';
import CardWrapper from '../../../../../../components/cardWrapper';
import CardDivider from '../../../../../../components/cardDivider';
import './shopBrain.css';

const GET_COLLECTION_PRODUCTS = gql`
        query Nodes($ids: [ID!]!){
            nodes(ids: $ids) {
            ...on Collection {
                id,
                handle,
                products(first:100){
                    edges {
                        node {
                        id
                        }
                    }
                },
            }
            }
        }
`
const DeleteWrapper = styled.div`
    cursor: pointer;
`
const ProductPicker = styled.div`
    .mockInputContainer-error {
        background-color: var(--p-surface-critical-subdued);
        border-color: var(--p-border-critical);
    }
`
const ExclusionTextWrapper = styled.div`
    span {
      line-height: 36px;
    }
`
const InfoIconWrapper = styled.div`
`
function ShopBrain(props) {
  const { host, token, /*versionAnalytics, versionData, setVersionData, version, setVersion, checkoutOfferType,*/ shop, state, setState, productPickerShow, setProductPickerShow, offerType, currency, currencySymbol, checkoutOfferType, /*locale, addSmartRule*/ } = props
  const normalizeShopifyID = (id) => {
    return Number(
      String(id)
        .replace('gid://shopify/ProductVariant/', '')
        .replace('gid://shopify/Product/', '')
    );
  }
  const [productIndex, setProductIndex] = useState(null)
  const [inclusionCollectionPickerVisible, setInclusionCollectionPickerVisible] = useState(false)
  const [collectionPickerVisible, setCollectionPickerVisible] = useState(false)
  const [mulProductPickerVisible, setMulProductPickerVisible] = useState(false)
  const [getInclusionCollectionProducts, { loading: inclusionLoading, error: inclusionError, data: inclusionData }] = useLazyQuery(GET_COLLECTION_PRODUCTS);
  const [getCollectionProducts, { loading, error, data }] = useLazyQuery(GET_COLLECTION_PRODUCTS);
  useEffect(() => {
    if(productIndex !== null && !inclusionLoading && inclusionError === undefined && inclusionData ){
      setState(update(state,{shopBrain:{inclusion:{[productIndex]:{value: {$set: inclusionData.nodes.map(node => {return {id: node.id, handle: node.handle, products:node.products.edges.map(product => normalizeShopifyID(product.node.id))}})}}}}}))
    }
  }, [inclusionData]);
  useEffect(() => {
    if(productIndex !== null && !loading && error === undefined && data ){
      setState(update(state,{shopBrain:{exclusion:{[productIndex]:{value: {$set: data.nodes.map(node => {return {id: node.id, products:node.products.edges.map(product => normalizeShopifyID(product.node.id))}})}}}}}))
    }
  }, [data]);
  const handleInclusionSelectChange = (index, value) => {
    setState(update(state,{shopBrain: {inclusion: { [index]: {setting: {$set: value} , value: {$set: null}}}}}))
  }
  const handleSelectChange = (index, value) => {
    setState(update(state,{shopBrain: {exclusion: { [index]: {setting: {$set: value} , value: {$set: null}}}}}))
  }
  const saveInclusionValue = (index, value) => {
    setState(update(state,{shopBrain:{inclusion:{[index]:{value: {$set: value}}}}}))
  }
  const saveExclusionValue = (index, value) => {
    setState(update(state,{shopBrain:{exclusion:{[index]:{value: {$set: value}}}}}))
  }
  const addAndInclusion = () => {
    if (checkoutOfferType === "autopilot") {
      setState(update(state,{shopBrain:{inclusion:{$push: [{setting: 'collection',value: null}]}}}))
    } else {
      setState(update(state,{shopBrain:{inclusion:{$push: [{setting: 'tags',value: null}]}}}))
    }
  }
  const addAndExclusion = () => {
    setState(update(state,{shopBrain:{exclusion:{$push: [{setting: 'tags',value: null}]}}}))
  }
  const deleteInclusion = (index) => {
    setState(update(state,{shopBrain:{inclusion:{$splice: [[index, 1]]}}}))
  }
  const deleteExclusion = (index) => {
    setState(update(state,{shopBrain:{exclusion:{$splice: [[index, 1]]}}}))
  }

  let inclusionOptions = checkoutOfferType === 'ai' ? [
    {label: 'Tag(s)', value: 'tags'},
    {label: 'Collection', value: 'collection'},
  ] :
  [
    {label: 'Collection', value: 'collection'},
  ]
  let exclusionOptions = [
    {label: 'Tag(s)', value: 'tags'},
    {label: 'Product type', value: 'product type'},
    {label: 'Collection', value: 'collection'},
    {label: 'Requires shipping', value: 'requires shipping'},
  ]

  return (
    <CardWrapper title={checkoutOfferType === 'ai' ? 'AI Instructions' : 'Autopilot Instructions'} subtitle={checkoutOfferType === 'ai' ? 'Number of products to show, Exclude products, Compare at Price' : 'Number of products to show, Recommend items, Compare at Price'} >
        <div style={{paddingTop: '20px'}}>
          <div>
          {("checkout" === offerType || "product" === offerType) &&
            (
            <div>
            <RangeSlider
              label="Set maximum number of products to show:"
              max={10}
              suffix={<p>{state.productNumber}</p>}
              value={state.productNumber}
              onChange={(val) => setState(update(state, { productNumber: { $set: val }}))}
              output
            />
            <CardDivider />
            </div>
            )
            }
            <div style={("checkout" === offerType || "product" === offerType)?{marginTop:'20px'}:{}}>
              <LegacyStack wrap={false} alignment="center">
                <Text as="h3" variant="headingSm">Only recommend items from</Text>
                <InfoIconWrapper>
                  <Tooltip dismissOnMouseOut content="Default is all active products that are in stock">
                    <Icon source={InfoIcon} tone="base" />
                  </Tooltip>
                </InfoIconWrapper>
              </LegacyStack>
              { state?.shopBrain?.inclusion?.map((item, index) => {
                return <div style={{padding: '10px 0'}} key={index}>
                  <LegacyStack wrap={false} alignment="center">
                    <LegacyStack.Item fill>
                      <div>
                        <LegacyStack wrap={false}>
                          <LegacyStack.Item>
                            <ExclusionTextWrapper>
                              <Text tone="subdued">Products with</Text>
                            </ExclusionTextWrapper>
                          </LegacyStack.Item>
                          <LegacyStack.Item>
                            <Select
                              options={inclusionOptions}
                              onChange={(value) => handleInclusionSelectChange(index, value)}
                              value={item.setting}
                            />
                          </LegacyStack.Item>
                          <LegacyStack.Item fill>
                            { 'tags' === item.setting &&
                              <TagModal saveExclusionValue={saveInclusionValue} item={item} index={index}/>
                            }
                            { "collection" === item.setting &&
                              <CollectionModal
                                state={state}
                                setState={setState}
                                type={'inclusion'}
                                host={host}
                                shop={shop}
                                token={token}
                                index={index}
                                setProductIndex={setProductIndex}
                                inclusionOption={item}
                                productPickerShow={productPickerShow}
                                setProductPickerShow={setProductPickerShow}
                                getInclusionCollectionProducts={getInclusionCollectionProducts}
                                collectionPickerVisible={inclusionCollectionPickerVisible}
                                setCollectionPickerVisible={setInclusionCollectionPickerVisible}
                              />
                            }
                          </LegacyStack.Item>
                        </LegacyStack>
                      </div>
                    </LegacyStack.Item>
                    <div className='deleteIconWrapper'>
                      {index > 0 ?
                        <LegacyStack.Item>
                          <DeleteWrapper onClick={()=>deleteInclusion(index)}>
                            <Button icon={DeleteIcon} tone="critical"></Button>
                          </DeleteWrapper>
                        </LegacyStack.Item>
                        :
                        false
                      }
                    </div>
                  </LegacyStack>
                </div>
              })
              }
              <Button  onClick={() => addAndInclusion()} variant="primary">
                + AND
              </Button>
              <CardDivider />
            </div>

            {
              checkoutOfferType === 'ai' &&
                <div style={{marginTop:'20px'}}>
                  <Text as="h3" variant="headingSm">Exclude from recommendations</Text>
                { state?.shopBrain?.exclusion.map((item, index) => {
                  return <div style={{padding: '10px 0'}} key={index}>
                    <LegacyStack wrap={false} alignment="center">
                      <LegacyStack.Item fill>
                        <LegacyStack wrap={false}>
                          <LegacyStack.Item>
                            <ExclusionTextWrapper>
                              <Text tone="subdued">Exclude products with</Text>
                            </ExclusionTextWrapper>
                          </LegacyStack.Item>
                          <LegacyStack.Item>
                            <Select
                              options={exclusionOptions}
                              onChange={(value) => handleSelectChange(index, value)}
                              value={item.setting}
                            />
                          </LegacyStack.Item>
                          <LegacyStack.Item fill>
                            { 'tags' === item.setting &&
                            <TagModal saveExclusionValue={saveExclusionValue} item={item} index={index}/>
                            }
                            { 'product type' === item.setting &&
                            <ProductTypeModal saveExclusionValue={saveExclusionValue} item={item} index={index}/>
                            }
                            { "collection" === item.setting &&
                            <CollectionModal
                              state={state}
                              setState={setState}
                              type={'exclusion'}
                              host={host}
                              shop={shop}
                              token={token}
                              index={index}
                              setProductIndex={setProductIndex}
                              inclusionOption={item}
                              productPickerShow={productPickerShow}
                              setProductPickerShow={setProductPickerShow}
                              getInclusionCollectionProducts={getCollectionProducts}
                              collectionPickerVisible={collectionPickerVisible}
                              setCollectionPickerVisible={setCollectionPickerVisible}
                            />
                            }
                          </LegacyStack.Item>
                        </LegacyStack>
                      </LegacyStack.Item>
                      <div className='deleteIconWrapper'>
                        {index > 0 ?
                          <LegacyStack.Item>
                            <DeleteWrapper onClick={()=>deleteExclusion(index)}>
                              <Button icon={DeleteIcon} tone="critical"></Button>
                            </DeleteWrapper>
                          </LegacyStack.Item>
                          :
                          false
                        }
                      </div>
                    </LegacyStack>
                  </div>
                })
                }
                <Button  onClick={() => addAndExclusion()} variant="primary">
                  + AND
                </Button>
                <CardDivider />
              </div>
            }

          </div>
        </div>

        {("checkout" === offerType || "product" === offerType) && <div style={{paddingTop:'1rem'}}><LegacyCard sectioned><DiscountCart state={state} setState={setState} currency={currency} currencySymbol={currencySymbol} isAi={true}/></LegacyCard><CardDivider /></div>}
        {("checkout" === offerType || "product" === offerType) &&
                  <div style={{paddingTop:'1rem'}}>
                    <Text as="h3" variant="headingXs">Variant selector</Text>
                    <Checkbox
                      label="Require a selection"
                      checked={state.requireSelection}
                      onChange={(val) =>
                        setState(
                          update(state, { requireSelection: { $set: val }})
                        )
                      }
                    />
                    <p style={{ color: "#6D7175" }}>
                      If checked, the variant dropdown selector is empty by
                      default
                    </p>
                  </div>
                }
        {"pp" === offerType && <DiscountCard state={state} setState={setState} currency={currency} currencySymbol={currencySymbol} />}
      </CardWrapper>
  );
}

export default ShopBrain;